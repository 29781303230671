import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.map";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.date.to-string";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.function.bind";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.is-array";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/es6.array.from";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.map";

function _createForOfIteratorHelperLoose(o) {
  var i = 0;

  if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) {
    if (Array.isArray(o) || (o = _unsupportedIterableToArray(o))) return function () {
      if (i >= o.length) return {
        done: true
      };
      return {
        done: false,
        value: o[i++]
      };
    };
    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  i = o[Symbol.iterator]();
  return i.next.bind(i);
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(n);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

import { match } from "@reach/router/lib/utils";
import stripPrefix from "./strip-prefix";
import normalizePagePath from "./normalize-page-path";
var pathCache = new Map();
var matchPaths = [];

var trimPathname = function trimPathname(rawPathname) {
  var pathname = decodeURIComponent(rawPathname); // Remove the pathPrefix from the pathname.

  var trimmedPathname = stripPrefix(pathname, __BASE_PATH__) // Remove any hashfragment
  .split("#")[0] // Remove search query
  .split("?")[0];
  return trimmedPathname;
};
/**
 * Set list of matchPaths
 *
 * @param {Array<{path: string, matchPath: string}>} value collection of matchPaths
 */


export var setMatchPaths = function setMatchPaths(value) {
  matchPaths = value;
};
/**
 * Return a matchpath url
 * if `match-paths.json` contains `{ "/foo*": "/page1", ...}`, then
 * `/foo?bar=far` => `/page1`
 *
 * @param {string} rawPathname A raw pathname
 * @return {string|null}
 */

export var findMatchPath = function findMatchPath(rawPathname) {
  var trimmedPathname = cleanPath(rawPathname);

  for (var _iterator = _createForOfIteratorHelperLoose(matchPaths), _step; !(_step = _iterator()).done;) {
    var _step$value = _step.value,
        matchPath = _step$value.matchPath,
        path = _step$value.path;

    if (match(matchPath, trimmedPathname)) {
      return normalizePagePath(path);
    }
  }

  return null;
}; // Given a raw URL path, returns the cleaned version of it (trim off
// `#` and query params), or if it matches an entry in
// `match-paths.json`, its matched path is returned
//
// E.g. `/foo?bar=far` => `/foo`
//
// Or if `match-paths.json` contains `{ "/foo*": "/page1", ...}`, then
// `/foo?bar=far` => `/page1`

export var findPath = function findPath(rawPathname) {
  var trimmedPathname = trimPathname(rawPathname);

  if (pathCache.has(trimmedPathname)) {
    return pathCache.get(trimmedPathname);
  }

  var foundPath = findMatchPath(trimmedPathname);

  if (!foundPath) {
    foundPath = cleanPath(rawPathname);
  }

  pathCache.set(trimmedPathname, foundPath);
  return foundPath;
};
/**
 * Clean a url and converts /index.html => /
 * E.g. `/foo?bar=far` => `/foo`
 *
 * @param {string} rawPathname A raw pathname
 * @return {string}
 */

export var cleanPath = function cleanPath(rawPathname) {
  var trimmedPathname = trimPathname(rawPathname);
  var foundPath = trimmedPathname;

  if (foundPath === "/index.html") {
    foundPath = "/";
  }

  foundPath = normalizePagePath(foundPath);
  return foundPath;
};